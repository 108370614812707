<template>
  <div @click="goToLink" class="flex flex-col justify-between cursor-pointer rounded-lg hover:shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
    <div>
      <img class="object-cover h-56 w-full" :src="image" alt="Article image">
      <div class="pt-8 px-7 flex flex-col justify-start">
        <div class="text-xl font-semibold mb-2 text-blue-900">{{ title }}</div>
      
        <div class="text-lg mb-8 text-blue-900">
          {{ blurb }}
        </div>
      </div>
    </div>

    <div class="px-7 pb-12">
      <img v-if="logo" :class="{ 'filter grayscale': gray }" class="h-12 mb-10 rounded object-contain" style="max-width: 45%;" :src="logo" alt="Publisher logo">

      <div v-else class="h-12 mb-10 text-gray-500 transition ease-in-out duration-200 font-semibold text-3xl">
        {{ author }}
      </div>

      <button target="_blank" class="py-4 px-4 flex items-center gap-6 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
        {{ action }}
        <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    blurb: String,
    link: String,
    image: String,
    logo: String,
    author: String,
    action: String,
    gray: Boolean
  },
  methods: {
    goToLink() {
      window.open(this.link, '_blank')
    }
  }
}
</script>