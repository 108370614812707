<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-80 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Press
      </div>

      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-10">
        <PressItem 
          title="St. Louis Startup Founder Finds Traction in Silicon Valley" 
          blurb="2022 has gotten off to a flying start for St. Louis founder, Bash Jones, who joined SeedScout and headed West to meet investors and launch Homeplan..."
          :logo="require('@/assets/press/eq-yellow.png')"
          action="read more"
          image="https://entrepreneurquarterly.com/wp-content/uploads/2022/02/IMG_6451.jpg"
          link="https://entrepreneurquarterly.com/st-louis-startup-founder-finds-traction-in-silicon-valley/"
        />

        <PressItem 
          title="Introducing Homeplan" 
          blurb="Managing a home is time consuming. Period. From keeping track of a mortgage to organizing all your contractors, to even understanding the equity of your home..."
          author="WORDS"
          action="read more"
          image="https://homeplan-assets.s3.us-east-2.amazonaws.com/team-press-image.jpeg"
          link="https://words.seedscout.com/p/introducing-homeplan?s=r"
        />

        <PressItem 
          title="809 - Bash Jones (Homeplan) On Building Better Tools for Homeowners" 
          blurb="Bash Jones is the co-founder of Homeplan. Homeplan enables you to put your home on autopilot with a personal home manager...."
          :logo="require('@/assets/logos/seedscout.png')"
          action="listen"
          image="https://homeplan-assets.s3.us-east-2.amazonaws.com/team-press-image.jpeg"
          link="https://ftf.seedscout.com/809-bash-jones-homeplan-on-building-better-tools-for-homeowners/"
        />

        <PressItem 
          title="Welcome To HomePlan" 
          blurb="Alex and Bash join Marcus Boston of Always Forward Media on The Cognac Corner Podcast to discuss the origins of Homeplan, the pitfalls of homeownership and how they solve this problem."
          action="listen"
          :logo="require('@/assets/logos/afm.png')"
          :image="require('@/assets/podcast.png')"
          link="https://www.spreaker.com/user/afm31/ep-hp-audio"
        />

        <PressItem 
          title="036 - Bash Jones (Homeplan) On Helping Homeowners Manage Their Home" 
          blurb="Bash Jones is the co-founder of Homeplan. Homeplan makes it simple for homeowners to manage their entire home with a single dashboard..."
          action="listen"
          logo="https://homeplan-assets.s3.us-east-2.amazonaws.com/founders-party.jpeg"
          image="https://homeplan-assets.s3.us-east-2.amazonaws.com/team-press-image.jpeg"
          link="https://share.transistor.fm/s/39970059"
          gray
        />

        <PressItem 
          title="Hustling from Homelessness to Startup Success with Bash Jones (#70)" 
          blurb="Jim is joined by Bash Jones, Co-founder of Homeplan to discuss how you can translate acquired life-skills into a successful scalable tech company..."
          action="listen"
          :logo="require('@/assets/press/growthhit.png')"
          image="https://homeplan-assets.s3.us-east-2.amazonaws.com/team-press-image.jpeg"
          link="https://podcasts.apple.com/us/podcast/hustling-from-homelessness-to-startup-success-with/id1562388323?i=1000556157303"
          gray
        />
      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";
import PressItem from "@/components/press-item.vue";

export default {
  components: {
    Menu,
    Footer,
    PressItem
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>